@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --color-brand: #79AFEC;
    --color-body: #0A0A0A;
    --color-text: #666;
    --color-secondary: #4A4A4A;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-family: 'Syne', sans-serif;
  cursor: none;
}
a{
  text-decoration: none;
}
ul{
  list-style: none;
}
html{
    scroll-behavior: initial !important;
}
::selection {
  background: #222A35;
}
body{
    background: var(--color-body);
    overflow-x: hidden;
}
#spinner{
  animation: spinner 8s infinite linear;
}
.text__stroke{
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--color-brand);
}

.page__width{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.link__hover{
    position: relative;
}
.link__hover:hover:after{
    width: 100%;
}
.link__hover:after{
    content: '';
    width: 0;
    transition: width .3s ease-in-out;
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 3px;
    background: var(--color-brand);
}
.link__hover_green:after{
    background: #25d366;
}

.scale-cursor{
    background: white
}

.embla {
    --slide-spacing: 1rem;
    --slide-size: 70%;
    --slide-height: 19rem;
    padding: 5% 0;
    margin: 10% 0;
}
.embla__viewport {
    overflow: hidden;
}
.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    flex-direction: row;
    align-items: center;
    height: auto;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-appearance: none;
  appearance: none;
  background-color: none;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  border: 1px solid white;
  padding: 0;
  margin: 0;
  width: 12px;
  height: 12px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected {
  background-color: white;
}
@media screen and (min-width: 1360px){
  .page__width{
    width: 1280px;
  }
}
@keyframes spinner {
  0%{
    rotate: 0deg;
  }
  100%{
    rotate: 360deg;
  }
}